@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}